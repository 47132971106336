
.logo-image {
    position:absolute;
    display: table-row;
    width:70%;
    height:70%;

    top: 0; bottom:0; right:0; left:0;
    margin: auto;
}
.logo-background {
    position:absolute;
    background-color: rgb(255, 255, 255);
    border-radius: 50%;
    width:100%;
    height:100%;
}

.logo-wrapper {
    position:relative;
    width: 13vmin;
    height: 13vmin; 

    box-sizing: border-box;
    display: table;
}

@media screen and (max-width: 500px) {
    .logo-wrapper {
        display:none;
    }
}

@media screen and (max-height: 600px) {
    .logo-wrapper {
        display:none;
    }
}

