@import '../../global-layout-breakpoint.scss';

$presentation-side-padding: 10vw;

// Menu

$menu-background-color: rgb(136, 130, 167);
$menu-button-background-color:rgba(50, 54, 100, 0.87);
$menu-button-border-color: rgba(255, 255, 255, 0.432);
$menu-button-active-background-color: rgba(255, 255, 255, 0.185);
$menu-button-active-border-color: rgba(255, 0, 128, 0);

$menu-button-index-background-color: rgba(255, 255, 255, 0.411);
$menu-button-index-text-color: rgba(87, 87, 87, 0.664);
$menu-button-active-index-background-color: rgba(255, 255, 255, 0.829);
$menu-button-active-index-text-color: rgb(100, 38, 110);

$menu-height: 7.5vh;
$menu-max-height: 65px;
$button-icon-scale: 85%;
$button-index-scale: 30%;
$menu-padding:15px;
$menu-gap:15px;
$menu-margin-bottom:10px;
$total-menu-height: calc(#{$menu-padding} * 2 + min(#{$menu-height}, #{$menu-max-height}));


.menu {
    background-color: $menu-background-color;

    height:$menu-height;
    max-height:$menu-max-height;
    bottom: $menu-margin-bottom;
    
    @media screen and (max-width: $layout-breakpoint) {
        width: 100%;
        bottom: 0;
    }
    
    position:fixed;
    display: flex;
    justify-content: center;
    left: 50%;
    transform: translate(-50%,0%);
    z-index: 100;
    pointer-events: none;
    gap:$menu-gap;
    padding:$menu-padding;
    border-radius: 10px;
}
