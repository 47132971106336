.newsletter-component {
    pointer-events: all;
}

#mc-embedded-subscribe-form.validate {
    padding:0px;
}

#mc_embed_signup {
    background:rgba(255, 255, 255, 0); 
    clear:left; 
    font:14px Helvetica,Arial,sans-serif;  
    max-width:450px;
    width:auto;
}

.indicates-required {
    display:none;
}

.newsletterh2 {
    font-family: 'Assistant', sans-serif;
    color:white;
    text-transform: uppercase;
}

.mc-field-group {
    display: none;
}

.mc-field-group label {
    color:white;
    font-family: 'Assistant', sans-serif;
}

.brandingLogo {
    opacity: 0.6;
}

#mc_embed_signup {
    margin: 0 auto;
    margin-top:2em;
}

#mc_embed_signup .button {
    height:3em !important;
    width: 60% !important;
    background-color: rgb(29, 64, 139) !important;
}

#mc_embed_signup .foot {
    text-align: center;
    display: flex !important;
    justify-content: center;
}

#mc-embedded-subscribe {
    font-size: 1.5em !important;
}

.info-box form {
    margin-top:auto !important;
}
