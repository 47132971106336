@import '../presentation/presentation.scss';
@import './glow-on-hover.scss';

.presentation-box {
    //height:100vh;

    display: flex;
    align-items: center;
    justify-content: center;

    top:0;
    left:0;

    width: 80vw;
    max-width:500px;

    &.single {
        padding: 10%;
        background-color: rgba($color: #1a1a1a, $alpha: 0.7);
    
        max-width:550px;

        @media screen and (max-width: $layout-breakpoint) {
            padding: 0%;
            background-color: rgba($color: #1a1a1a00, $alpha: 0);    
        }
    }

}

.presentation-wrapper {
    display: flex;
    justify-content:center;
}

.box-wrapper {
    display:flex;
    flex-direction: column;

    //transition: ease 0.5s;
    background-color: rgba($color: #000000, $alpha: 0);
    border-radius:3vh;
    padding: 0vh;

    @media screen and (max-width: $layout-breakpoint) {
        background-color: rgba($color: #0c0c0c, $alpha: 0.83);
        padding: 2vh;

        &.hidden {
            transform: translate(120%, 0);
        }

    }

}

.hide-button {
    
    pointer-events: all;

    transform:scale(0.7);

    top: 1.5em;

    position: absolute;
    z-index:69;
    display:none;

    width: 8.8em;
    height: 4.4em;
    border: 0.4em solid white;
    border-radius: 1em;

    @media screen and (max-width: $layout-breakpoint) {
        display:flex;
    }

    & .hide-option {
        height: 4.4em;
        width: 4.4em;
        line-height:3.4em;
        text-align: center;
        color:#ffffff3f;

        font-size: 1.4em;


        &.active {
            color:#000000;
        }

        z-index:70;
    }

    & .hide-bg {
        height:4em;
        width:4em;
        top: 0.2em;
        left: 0.2em;
        background-color: #ffffff;
        position:absolute;
        border-radius:0.5em;

        transition: 0.5s;

        &.hide {
            transform:translate(4.4em, 0);
        }
    }

}

.presentation-info {
    height:60vh;
    min-height:365px;
    overflow-y:hidden;
    overflow-x:visible;
    
    position:relative;

    border:dotted;
    border-width: 0px 0 3px 0;

    &.single {
        overflow: auto;
        height: 70vh;
        pointer-events: all;
        border: none;

        & .presentation-section {
            justify-content:start;
            padding-bottom: 2vh;
            height:auto;
        }
    }
}

.presentation-section {
    width:94%;
    height:94%;
    padding:3%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 

    position:relative;
}

.info-box {

    display:inline-block;

    color:rgb(255, 255, 255);
    //transition:.5s;

    & input, select, textarea, button
    {
        font-family:inherit;
        font-size: inherit;
    }

    & h2 {
        font-size: 4.5em;
        font-weight: normal;
        line-height: 90%;
        margin-block-start: 0;
        margin-block-end: 0.5em !important;

        @media screen and (max-width: $layout-breakpoint) {
            font-size: 4em;
        }

        @media screen and (max-height: 850px) {
            font-size: 3em;
        }
        @media screen and (max-height: 700px) {
            font-size: 0;
        }
        
    }

    & h3 {
        font-size: 2em;
        font-weight: normal;
        line-height: 90%;
        margin-block-start: 0;
        margin-block-end: 0.5em !important;

        @media screen and (max-width: $layout-breakpoint) {
            font-size: 2em;
        }

        @media screen and (max-height: 850px) {
            font-size: 1.3em;
        }
        @media screen and (max-height: 700px) {
            font-size: 0;
        }
        
    }


    & p, & label {
        font-size: 1.1em;

        @media screen and (max-height: 850px) {
            font-size: 0.95em;
        }

        @media screen and (max-width: $layout-breakpoint) {
            font-size: 0.95em;
        }

        @media screen and (max-height: 620px) {
            font-size: 0.9em;
        }
    }

    & label {
        display: block;
        margin-top: 0.3em;

    }

    & .input-wrapper {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
    }

    & input {
        border: none;
        padding: 2%;
        margin-block-end: 0.7em;
        width:96%;
        border-radius: 3px;

        &.is-invalid {
            background-color: #ffecec;
            border-width: 20px;
        }
    
    }

    & .submit-button {
        left: 50%;
        transform:translate(-50%, 0);
        width: 60%;
        margin-top: 10%;
    }

    & .captcha-container {
        display:flex;
        justify-content:center;
    }

    & form {
        margin-top: 3em;
        margin-bottom: 1em;
    }

    & .invalid-feedback {
        color:#ffacac;
        margin-block-start: 0;
        text-align: right;
        font-size: 0.9em;
    }
}

.presentation-menu {

    margin: 1em 0;

    align-self: center;
    width: auto;
    max-width: 400px;
    height: 4em;
    display: flex; // Change to flex
    justify-content: center; // Center the buttons
    gap: 0.5em; // Gap between buttons
}

.menu-button {

    aspect-ratio: 1 / 1; // Add this line
    flex-grow: 1;
    pointer-events:all;
    cursor: pointer;
    //max-width: 4em;

    display: inline-block;
    position:relative;

    height: 100%;

    & a {
        width:100%;
        height:100%;
    }

    animation: glowing 160s linear infinite;
    @keyframes glowing {
        0% { background-position: 0 0; }
        50% { background-position: 400% 0; }
        100% { background-position: 0 0; }
    }
    background-size: 800%;

    border: 4px solid rgba($color: #ffffff, $alpha: 0.0);; // Set border width, style, and color
    border-radius: 10px; // Set border radius
    background-color: rgba($color: #ffffff, $alpha: 0.1); // Set background color

    & .menu-button-icon {
        position:absolute;
        max-width: 70%;
        max-height: 70%;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
    }

    @media (pointer: fine) {

        &:hover .menu-button-icon {
            opacity: 0.8;
        }
    }

    &.active {
        //border-color: rgba($color: rgb(84, 7, 136), $alpha: 1.0);
        //background-color: rgb(84, 7, 136);// Set background color when button is active

        animation: glowing 160s linear infinite;
        @keyframes glowing {
            0% { background-position: 0 0; }
            50% { background-position: 400% 0; }
            100% { background-position: 0 0; }
        }

        & .menu-button-icon {
            opacity:1;
            filter: drop-shadow(0 0 10px #424242); // Adjust as needed

        }
    }

    & .menu-button-tooltip {
        opacity: 0;
        text-align: center;
        position:absolute;
        z-index: 20;
        background-color:black;
        font-size:1em;
        padding:5px;

        margin: 0 50%;
        transform:translate(-50%, 0);
        top:-80%;
        white-space:nowrap;
        //transition:.5s;

        pointer-events: none;
    }

    @media (pointer: fine) {
        &:hover .menu-button-tooltip {
            opacity: 1;
        }
    }
}

.info-button {
    margin: 1em 0 1em 0;
    pointer-events: all;
}

/* custom scrollbar */
::-webkit-scrollbar {
    width: 20px;
  }
  
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #9b9b9b;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background-color: #cad8db;
  }