@import 'node_modules/react-modal-video/scss/modal-video.scss';

.info-table  {
    table-layout: fixed;
    width: 100%;

    font-size: 1.3em;

    & .wrapper {
        position: relative;
        //background-color: #383838;
        margin: 1%;
        
        &::after {
            content: "";
            display: block;
            padding-bottom: 100%;
          }


        cursor: pointer;

        pointer-events: all;

        &:hover {

            & .image {
                filter: grayscale(0%);
                opacity: 1;
            }

            & .standard {
                    color:rgba(0, 0, 0, 0);
                    background-color: #ffffff00;
            }
    
            & .hover {
                    color:black;
                    background-color: #ffffffb0;
            }
        }
    }

    & .image {
        position:absolute;

        background-size:cover;
        background-repeat:no-repeat;
        background-position:center;

        border-radius: 30%;

        width:100%;
        height:100%;
        top: 0;
        left: 0;
        background-color: #6161612f;

        filter: grayscale(10%);
        opacity: 0.7;

        transition: 0.3s;

        display:inline-block;
    }

    & .text {
        position:absolute;
        
        border-radius: 30%;

        width:100%;
        height:100%;
        top: 0;
        left: 0;
        line-height: 110%;
        display:flex;
        justify-content: center;
        align-items: center;

        transition: 0.3s;

        &.standard {
            background-color: rgba(0, 0, 0, 0);
            color: rgba(255, 255, 255, 1);    
            text-shadow: 2px 2px 6px #000000;

            // & div {
            //     background-color: rgba(0, 0, 0, 0.37);
            //     width:100%;
            //     text-align: center;
            // }

        }

        &.hover {
            background-color: rgba(0, 0, 0, 0);
            color: rgba(255, 255, 255, 0);    

        }


        & div {
            padding:10%;
            font-size: 70%;
        }

    }
}


  
