.collapsible {
    background-color: rgba($color: #f1f1f1, $alpha: 0.1);
    color: white;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
  }
  
.collapsible.active, .collapsible:hover {
    background-color: rgba($color: #f1f1f1, $alpha: 0.2);
  }
  
  .content {
    padding: 0 18px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    background-color: rgba($color: #f1f1f1, $alpha: 0.1);
  }

  .collapsible:after {
    content: '+'; /* Unicode character for "plus" sign (+) */
    font-size: 14px;
    color: white;
    float: right;
    margin-left: 5px;
  }
  
  .collapsible.active:after {
    content: "-"; /* Unicode character for "minus" sign (-) */
  }

  hr {
    border: none;
    border-top: 3px dotted #fff;
    color: #fff;
    background-color: none;
    height: 1px;
    width: 100%;
    margin-top: 6em;
    margin-bottom: 2em;
  }