
.beer-icon-box {
    display: flex;
    flex-direction: row;
    gap: 0.5em;

    pointer-events: all;
    cursor: pointer;
}

.beer-icon-box img {
    height: 3em;
    width: 3em;
}