.glhf-button {

    width: 220px;
    height: 50px;
    border: none;
    outline: none;
    color: #fff;
    background: #111;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;

    &:active {
        opacity:0.9;
        transform:scale(1.01);
    }

    &.boooring {
        color: rgb(172, 172, 172);
        background-color: #252525;
        opacity:0.8;
    }

    &.glow {
        background: rgba($color: #28243d, $alpha: 0.95);
    }

    &.glow:active:after {
        background: rgba($color: #000000, $alpha: 0.30);

    }

    &.glow:before {
        content: '';
        background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
        position: absolute;
        top: -2px;
        left:-2px;
        background-size: 400%;
        z-index: -1;
        filter: blur(5px);
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        animation: glowing 20s linear infinite;
        opacity: 1;
        transition: opacity .3s ease-in-out;
        border-radius: 10px;    
    }

    &.glow:after {
        z-index: -1;
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba($color: #000000, $alpha: 0.90);
        opacity: 1;
        left: 0;
        top: 0;
        border-radius: 10px;
        transition: 0.3s;
    }
}


.glow-on-hover:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 1;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}