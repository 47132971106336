@import './global-layout-breakpoint.scss';

.clickable-link {
    
    cursor: pointer;

    background: rgb(0,248,191);
    background: linear-gradient(90deg, rgba(0,248,191,1) 0%, rgba(93,161,255,1) 100%);
    // background: -webkit-linear-gradient(#eee, #333);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.center-item {
    left: 50%;
    transform:translate(-50%, 0);
}

.modal-video {
    pointer-events: all;
}

.fade-in {
    &.unloaded {
        opacity: 0;
    }

    &.loaded{
        animation: fadeInSlow ease-in-out 3s;

        @keyframes fadeInSlow {
            0% { opacity: 0; }
            100% { opacity: 1; }
          }
    }
}

.CookieConsent {
    
    top:0;
    z-index:998;
//    bottom:4vh;
    width:100vw;
    height:100vh;

    position:absolute;

    display: flex;
    flex-direction: column;
    gap:2em;
    flex-wrap:nowrap;
    justify-content: center;
    align-items: center;
    padding:0;
    left:50vw;
    transform:translate(-50%, 0);
    //transition:0.5s;

    animation: fadeIn 2s;
    
    @keyframes fadeIn {
        0% { opacity: 0; }
        100% { opacity: 1; }
      }

    &h2 {
        display:block;
        font-size:1.5em;
        margin-block-start: 0.83em;
        margin-block-end: 0.83em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
    }

    &:before {
        content: ' ';
        background-image: url('/bg.jpg');
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-size:cover;
        background-position: 50% 0;
        //transition:0.5s;

        opacity: 0.60;
    }

    &:after {
        content: ' ';
        background-color: rgba(0, 0, 0, 0.849);
        position:absolute;
        width:100%;
        height:40%;

        @media screen and (max-width: $layout-breakpoint) {
            height:60%;
            background-color: rgba(0, 0, 0, 0.9);
        }

    }

    & .consent-content {
        
        z-index:999;
        font-size:1.1em;
        text-align: center;
        color:rgb(255, 255, 255);
        padding:0 10%;

        display:flex;
        justify-content: center;
        align-items: center;
        align-content:center;
        justify-items:center;
        //transition:0.5s;

    }

    & .consent-button-wrapper {
        z-index:999;

        position:relative;
        display:flex;
        flex-wrap:wrap;
        justify-content: center;
        margin:1.5em;
        gap:2em;
        //transition:0.5s;

    }
}