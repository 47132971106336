@import '../presentation/presentation.scss';
@import '../../global-layout-breakpoint.scss';

.canvas-container {
    margin:0;
    width:140vw;
    height:100vh;

    $xTrans: calc(-20%*1.4);
    transform:translate($xTrans, 0);
    position:absolute;
    z-index: 2;

    & canvas {
        display:block;
        overflow:hidden;
    }

    @media screen and (max-width: $layout-breakpoint) {
        font-size: 3.5em;
        transform:translate(0, 0);
        width:100vw;
    }

}

body {
    overflow: hidden;
}

.slide {
    width:100vw;
    height:100vh;
    margin:0;

    position:absolute;
    top: 0;
    left: 0;

    background-color: #9900a7;
    overflow: hidden;
}

.bg-layers {
    position:fixed;
    left: 0%;
    top: 0%;
    width:100vw;
    height:100%;
    object-fit: cover;
}

.flex-wrapper {

    pointer-events: none;

    position:absolute;
    margin:0;
    padding:0;
    left: 0;
    top: 0;

    width: 100vw;
    height: 100%;

    display:grid;
    grid-template-columns: 1fr 1fr;
    justify-items: stretch;
    align-items: center;

    /* When the flex breaks and wraps, hide empty columns (left or right) */
    @media screen and (max-width: $layout-breakpoint) {
          grid-template-columns: 1fr;
          display:contents;
    }

}

.centering-wrapper {
    margin:auto;
    padding:0;
    display:inline-block;
}

.content-wrapper {

    display:grid;
    justify-items: center;
    align-items: center;
    height:100%;
    width:100%;

    //padding: 5vw 5vw;
    position: relative;
    z-index:10;

    @media screen and (max-width: $layout-breakpoint) {
        position: absolute;
        top:0;
        bottom:0;
        z-index:10;
    }

    &:empty {
      padding: 0;
    }
}
