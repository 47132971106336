.privacy-link {
    position:fixed;
    z-index: 69;
    right: 9px;
    bottom: 0;
    
    & a {
        color:white;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}