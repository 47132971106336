@import '../presentation/presentation.scss';

.textblock {
    height:auto;
    width:auto;
    margin-left: auto;
    margin-right: auto;
    display:inline-block;
    text-align:left;
}

.textblock h1 {
    font-size: 4.5em;
    font-weight: normal;
    line-height: 100%;
    margin: 0;
    margin-bottom: 5vh;

    @media screen and (max-width: $layout-breakpoint) {
        font-size: 9vw;
    }

}

.textblock h1 .title {
    color:white;
}

.textblock p {
    max-width: 400px;
    color:white;
}
