
.epic-button {

    width: 100%;

    // width: calc(80vw * 0.94);
    // max-width:calc(500px * 0.94);


    height: 5em;
    flex: 1;

//    background: linear-gradient(0deg, rgba(255,224,51,1) 0%, rgba(255,0,255,1) 100%);
    // background: linear-gradient(45deg, #ffe033, #ff00ff, #fffb00, #ff9800, #ffe033);
    animation: glowing 160s linear infinite;
    border-radius: 0.5em;
    background-size: 800%;

    position:relative;
    margin-bottom: 1em; 

    cursor: pointer;
    pointer-events: all;

    & .red {
        opacity:0;
    }

    &:hover {
        & .red {
            opacity:1;
        }
            
        & .gray {
            opacity:0;
        }
    }

    & .image-container {
        height:6em;
        transform:translate(-1.5em, -1em);
        overflow:hidden;
        position:relative;
    }

    & img {
        position:absolute;
        width:8em;
        height:8em;
    }

    & .picture-single {
        height:6em;
        width:12em;
        bottom:0;
        left:2em;
    }

    & .picture1 {
        left:0%;
        rotate: -20deg;
        z-index: 10;
    }

    & .picture2 {
        z-index: 12;
        left:3.5em;
    }

    & .picture3 {
        left:7em;    
        rotate: 20deg;
        z-index: 11;
    }

    & .text {
        text-shadow: 2px 2px 4px #000000;
        font-size: 2em;
        z-index: 13;
        position: absolute;
        right:0.5em;
        bottom:0;
        color:white;
    }

    & .play-button {
        position:absolute;
        width:4em;
        height:4em;
        top:-1.3em;
        right:0.8em;
        transition:0.5s;
    }

    @keyframes glowing {
        0% { background-position: 0 0; }
        50% { background-position: 400% 0; }
        100% { background-position: 0 0; }
    }

    &.simple {
        height: 2em;

        display:inline-block;

        .text {
            font-size:1em;
        }
    }


}

