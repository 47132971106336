/* Add this CSS to your stylesheet */
.spinner {
    border: 16px solid #9b02b6;
    border-top: 16px solid #3498db;
    border-radius: 50%;
    width: 5vw;
    height: 5vw;
    animation: spin 2s linear infinite;
    position: absolute;
    bottom: 2.5vw;
    left: 2.5vw;
    transform: translate(-50%, -50%);
    display: none; /* Initially hidden */
  }

#gmap {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  